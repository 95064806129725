import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo/seo'
import Layout from '../components/layout/Layout'

const JobList = ({ data }) => {
  const [openings, setOpenings] = useState([])

  useEffect(() => {
    setOpenings(data?.allJobPost?.nodes || [])
  })

  useEffect(() => {
    setTimeout(() => {
      window.AOS.init({
        duration: 1200,
        easing: 'ease-out-back',
      })
      window.jQuery('.photo-gallery').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
      })

      window.jQuery(function () {
        window.jQuery('.open-photo-gallery').click(function () {
          window.jQuery('body').addClass('active')
        })
        window.jQuery('.photo-gallery-modal-close').click(function () {
          window.jQuery('body').removeClass('active')
        })
      })
    }, 700)
  }, [])

  return (
    <>
      <Seo
        title={'Careers | immence'}
        description={'We are always in search of talented people.'}
      />
      <Layout>
        <div>
          <section className="content-page bg-service" id="service_page">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="content_padd">
                    <div className="row">
                      <h2 className="hero-heading">
                        Talents <br />
                        <span className="blue-span"> wanted </span>
                      </h2>
                    </div>
                    <div className="row">
                      <p className="section-main-para heading-setting mb-0">
                        We're looking for passionate and creative minds
                        individuals to join our team. Bring your talent and
                        expertise to our team that values innovation,
                        collaboration, and growth.
                      </p>
                      <p className="section-main-para heading-setting mb-0 mt-1">
                        Let's Grow Together!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 center-item">
                  <div className="service_img">
                    <img
                      src={require('../img/heroImg/careers.png').default}
                      alt="insights"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="com-section open-postion-section"
            id="open-job-list"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="hero-heading offer-section-heading text-center">
                    {openings.length > 0
                      ? 'Openings'
                      : 'Currently Full: Future Talent Needed'}
                  </h2>
                </div>
                {/* {openings.length > 0 && ( */}
                <div className="col-lg-12">
                  <div className="open-job-list">
                    <div className="row">
                      <div className="col-lg-8 col-md-12 mx-auto">
                        {/* Openings list */}
                        {openings.map((data) => (
                          <div className="job-list" data-aos="fade-up">
                            <a
                              href={`/careers/${data?.slug}`}
                              className="job-list-a"
                            >
                              <div className="job-wrap">
                                <h2 className="job-title">{data?.title}</h2>
                                <div className="d-flex">
                                  <div className="job-type me-2">
                                    <p>{data?.jobTypeName}</p>
                                  </div>
                                  <div className="exp-yrs">
                                    <span>•</span>
                                    <p>{data?.experienceLevel}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="apply-btn">
                                <p>Apply now</p>
                              </div>
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    allJobPost(sort: { fields: updatedAt, order: DESC }) {
      nodes {
        id
        title
        slug
        experienceLevel
        workTypeId
        jobTypeId
        overview
        responsibilities
        skillsAndRequirements
        workTypeName
        jobTypeName
        publish
        isActive
        isDeleted
        createdAt
        updatedAt
      }
    }
  }
`

export default JobList
